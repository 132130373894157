/* ==========================================================================
   Table of Content
   ==========================================================================

    Global
        Body
        Links

    General
        Wrappers and containers
        Article elements
        Separators
        Ratings
        Buttons and forms
        Colors
        Media Queries
        Margins and paddings

    Header mobile
        Branding
        Navigation

    Header desktop
        Branding
        Search
        Weather
        Navigation
            Subnav - full size menu
            Dropnav - regular drop down menu
            Sub navigation - categories

        Media Queries

    Slider
        Slider
        Media Queries

    Content
        Articles
            Media Queries
        News Layouts
            news-lay-1
            news-lay-2
            news-lay-3
            Media Queries

        Reviews
        Videos
            Media Queries

        Article page
            Blockquote
            Info box
            Author box
            Highlights
            Reviews
            Footer
            Comments
            Iframes
            Media Queries

        Archive page
            Timeline

        Author page

    Sidebar
        Timeline module
        Quote module
        Singles module
        News module
        Photos module
        Media Queries

    Footer
        Navigation
        About
        Copyright
        Media Queries

    Banners
        Banners
        Media Queries




/* ==========================================================================
   Global
   ========================================================================== */

/**
 * Body
 */

body {
  font-family: 'Roboto', sans-serif;
  color: #5c5c5c;
  background: #fff;
  padding: 0 0 20px 0;
}

/**
 * Links
 */

a {
  text-decoration: none;
  color: #222;
  -webkit-transition: color 200ms ease, background-color 200ms ease, text-shadow 200ms ease, box-shadow 200ms ease, border 200ms ease;
  transition: color 200ms ease, background-color 200ms ease, text-shadow 200ms ease, box-shadow 200ms ease, border 200ms ease;
}

a:hover {
  color: #111;
  text-decoration: none;
}


/* ==========================================================================
   General
   ========================================================================== */

/**
 * Wrappers and containers
 */

#page-outer-wrap {
  position: relative;
  overflow: hidden;
  width: 100%;
}

#page-inner-wrap {
  position: relative;
  width: 100%;
}

.relative {
  position: relative;
}

.container {
  padding: 0;
}

//.container  .img-responsive {
//  display: block;
//  width: 100%;
//  max-height: 100%;
//}

#page-content {
  position: relative;
  overflow: hidden;
  padding: 0;
  box-shadow: 0 0 12px 0 #999;

  @media (min-width: $screen-sm-min) {
    margin: 30px 0 0 0;
  }
}

.ie8 #page-content {
  border: 1px solid #e3e3e3;
  margin: 30px -1px 0 -1px;
}

#main {
  padding: 10px;
}

#main.wide {
  float: none;
  width: 100%;
}

#sidebar {
  color: #bbb;
  background: #444;
  padding: 15px;
}

.section-full {
  margin: 0 -15px 0 -15px;
  padding: 45px 15px 0 15px;
}

.section-full-even {
  margin: 0 -15px 0 -15px;
  padding: 15px 15px 0 15px;
}


/**
 * Article elements
 */

article {
  position: relative;
  margin-bottom: 25px;
}


.theme {
  position: absolute;
  top: 10px;
  left: 0;
  font: 700 13px/25px 'Roboto Condensed', sans-serif;
  color: #fff;
  text-transform: uppercase;
  background: #444;
  padding: 0 12px;
}

.overlay {
  position: absolute;
  color: #eee;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 12px 15px;
  background: rgb(0, 0, 0); /* Fall-back for browsers that don'tsupport rgba */
  background: rgba(0, 0, 0, .4);
}

.large .overlay {
  padding: 15px 20px;
}

.overlay h1,
.overlay h2,
.overlay h3 {
  margin: 0;
}

.overlay h1 {
  font-size: 24px;
  padding: 5px;
}

.overlay p {
  margin: 6px 0 0 0;
  opacity: 0.8;
}

.overlay a {
  color: #fff;
}

.overlay .breadcrumb {
  margin: 0 0 0 10px;
  padding: 0;
}

.overlay .sharing {
  position: absolute;
  font-size: 14px;
  right: 25px;
  bottom: 25px;
}

.overlay .sharing a {
  display: inline-block;
  margin-left: 12px;
  opacity: 0.6;
}

.overlay .sharing a:hover {
  opacity: 1.0;
}


/**
 * Separators
 */

.separator-left {
  border-left: 1px solid #e3e3e3;
}

.separator-right {
  border-right: 1px solid #e3e3e3;
}

.separator-bottom {
  border-bottom: 1px solid #e3e3e3;
}


/**
 * Ratings
 */

.review-rating {
  text-align: center;
}

ul.rating, ul.rating li {
  display: inline;
  margin: 0px;
  padding: 0px;
  list-style: none;
}

ul.rating li {
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-right: 1px;
  background: url(../../images/stars.png) no-repeat 0 0;
}

ul.rating li.li-rated {
  background-position: 0 100%;
}


/**
 * Buttons and forms
 */

.show-more {
  display: inline-block;
  font-size: 18px;
  line-height: 50px;
  color: #fff;
  background: #444;
  border: none;
  padding: 0 40px;
  margin: 10px 0 50px 0;
  -webkit-border-radius: 25px 25px 25px 25px;
  border-radius: 25px 25px 25px 25px;
}

#page-content .show-more:hover {
  background: #444;
}

.error {
  font-weight: 400;
  color: red;
  margin-top: 5px;
}


/**
 * Colors
 */

.cat-business-light {
  background: #cce0cd;
}


/**
 * General - Media Queries
 */

@media (min-width: 768px) {

  #main {
    padding: 15px;
  }

  #sidebar {
    top: 0;
    right: 0;
    bottom: 0;
    background: #444;
    margin-bottom: -9000px; /* 100% height */
    padding-bottom: 9000px;
  }

  .section-full {
    margin: 0 -20px 0 -20px;
    padding: 45px 15px 0 20px;
  }

  .section-full-even {
    margin: 0 -15px 0 -20px;
    padding: 15px 15px 0 20px;
  }

  .overlay h1 {
    padding:  10px;
  }

}

@media (min-width: 992px) {

  #main {
    float: left;
    width: 640px;
    padding: 0 20px;
  }

  #main.article {
    padding: 20px;
  }

  #main .col-md-9 {
    padding-top: 20px;
    padding-right: 20px;
  }

  #main .col-md-3 {
    padding-top: 20px;
    padding-left: 20px;
  }

  #sidebar {
    float: right;
    width: 330px;
  }

  .overlay h1 {
    font-size: 36px;
    padding:  15px 350px 15px 15px;
  }

  .overlay .breadcrumb {
    margin-left: 15px;
  }

}

@media (min-width: 1170px) {

  .section-full {
    padding: 45px 25px 0 25px;
  }

  .section-full-even {
    margin: 0 -25px 0 -25px;
    padding: 15px 15px 0 25px;
  }

}

@media (min-width: 1200px) {

  #main {
    width: 820px;
    padding: 0 25px;
  }

  #main.article {
    padding: 25px;
  }

  #main .col-md-9 {
    padding-top: 25px;
    padding-right: 25px;
  }

  #main .col-md-3 {
    padding-top: 25px;
    padding-left: 25px;
  }

  #sidebar {
    width: 350px;
    padding: 23px;
    margin-bottom: -9000px; /* 100% height */
    padding-bottom: 9000px;
  }

  .section-full {
    margin: 0 -25px 0 -25px;
  }

}


/**
 * Margins and paddings
 */

.top-margin {
  margin-top: 20px;
}

#page-content .bottom-margin {
  margin-bottom: 25px;
}

.top-padding {
  padding-top: 15px;
}

.bottom-padding {
  padding-bottom: 15px;
}

.no-padding {
  padding: 0;
}

.form-control {
  padding: 10px 12px;
  height: 42px;
}

.icon-padding {
  position: relative;
  padding-left: 30px;
}

.icon-padding i.fa {
  position: absolute;
  top: 4px;
  left: 0;
}



/* ==========================================================================
   Header mobile
   ========================================================================== */

/**
 * Header mobile
 */

#page-header-mobile {
  position: relative;
  /*border-top: 5px solid #3a3a3a;*/
  background: #3c3c3c;
  height: 50px;
  overflow: hidden;
  padding: 5px 10px 0 10px;
}

/**
 * Branding
 */

#page-header-mobile h1 {
  margin: 0;
  padding: 1px 0;
}

#page-header-mobile h1 img {
  vertical-align: inherit;
}


/**
 * Navigation
 */

#page-header-mobile a {
  color: #eee;
}

#page-header-mobile a:hover {
  color: #fff;
}

#page-header-mobile span {
  font-size: 24px;
  margin-top: 5px;
}

#page-header-mobile nav {
  background: #2d2f33;
  display: block;
  height: 100%;
  overflow: auto;
  position: fixed;
  right: -20em;
  font-size: 15px;
  top: 0;
  width: 20em;
  z-index: 2000;

  transition: right 0.3s ease-in-out 0s;
  -webkit-transition: right 0.3s ease-in-out 0s;
  -moz-transition: right 0.3s ease-in-out 0s;
  -o-transition: right 0.3s ease-in-out 0s;
}

body.nav-expanded #page-header-mobile nav {
  right: 0;
}


/* ==========================================================================
   Header desktop
   ========================================================================== */

/**
 * Header desktop
 */

#page-header h1 {
  color:  #fff;
  margin: 0;
}


/**
 * Branding
 */

#header-branding {
  position: relative;
  height: 120px;
  background: #444;
  border-top: 5px solid #3a3a3a;
  border-bottom: 1px solid #4d4d4d;
  overflow: hidden;
}

#header-branding h1 {
  padding: 14px 0 0 6px;
}


/**
 * Search
 */

/*#search-form {*/
/*width: 360px;*/
/*height: 45px;*/
/*border: 3px solid #4a4a4a;*/
/*text-align: left;*/
/*margin-top: 22px;*/
/*padding: 0 10px;*/
/*-webkit-border-radius: 25px 25px 25px 25px;*/
/*border-radius: 25px 25px 25px 25px;*/
/*-webkit-box-shadow:inset 0 3px 0 0 #3d3d3d;*/
/*box-shadow:inset 0 3px 0 0 #3d3d3d;*/
/*overflow: hidden;*/
/*}*/

/*#search-form input {*/
/*line-height: 36px;*/
/*height: 36px;*/
/*color: #999;*/
/*background: #444;*/
/*border: none;*/
/*width: 300px;*/
/*padding: 0 5px;*/
/*}*/


/*#search-form button {*/
/*line-height: 36px;*/
/*height: 36px;*/
/*color: #a1a1a1;*/
/*background: #444;*/
/*border: none;*/
/*width: 30px;*/
/*}*/

/**
 * Weather
 */

#header-branding .weather {
  position: relative;
  display: inline-block;
  text-align: left;
  padding: 0 80px 0 0;
  margin-top: 28px;
}

#header-branding .weather h3 {
  font-size: 14px;
  font-weight: 400;
  color: #c3c3c3;
  margin: 0;
}

#header-branding .weather h3 span.temp {
  display: inline-block;
  font-weight: 700;
  color: #fff;
  margin-left: 15px;
}

#header-branding .weather span.glyphicon {
  display: inline-block;
  margin-right: 5px;
}

#header-branding .weather span.date {
  font-size: 12px;
  color: #878787;
  padding-left: 22px;
}

#header-branding .weather i.icon {
  position: absolute;
  top: 0;
  right: 10px;
  font-size: 28px;
  color: #eee;
}


/**
 * Navigations
 */

#sticky-header.affix {
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
}

#header-navigation {
  position: relative;
  /*height: 50px;*/
  background: #3f3f3f;
}

#header-navigation .container {
  position: relative;
}

#header-navigation ul {
  padding: 0;
  margin-top: -1px;
  border-left: 1px solid #4d4d4d;
}

#header-navigation ul ul {
  margin-top: 0;
}

#header-navigation ul li {
  position: static;
  list-style: none;
  float: left;
  border-right: 1px solid #4d4d4d;
}

#header-navigation ul li a {
  display: block;
  font-size: 12px;
  font-weight: 700;
  line-height: 50px;
  color: #fff;
  text-transform: uppercase;
  padding: 0 10px;
  border-top: 1px solid #222;
}

#header-navigation ul li li a {
  border-top: none;
}

/*#header-navigation ul li.current a,*/
/*#header-navigation ul li a:hover,*/
/*#header-navigation ul li a:focus {*/
/*background: #222;*/
/*}*/

#header-navigation ul li.options {
  float: right;
  padding-right: 10px;
}

#header-navigation ul li.options a {
  display: inline-block;
  font-size: 12px;
  color: #999;
  padding: 0 10px;
  border: none;
}

#header-navigation ul li.options a span.glyphicon {
  padding-right: 8px;
  color: #999;
}

#header-navigation ul li.options a:hover,
#header-navigation ul li.options a:hover span {
  background: none;
  color: #fff;
}


/**
 * Subnav - full size menu
 */

#header-navigation .subnav-container {
  display: none;
  position: absolute;
  left: 0;
  top: 50px;
  z-index: 999;
  width: 750px;
  //height: 260px;
  overflow: hidden;
  background: #3f3f3f;
}

#header-navigation ul li:hover .subnav-container {
  display: block;
}

#header-navigation ul.subnav-menu {
  position: absolute;
  left: -10px;
  top: 10px;
  width: 200px;
  height: 240px;
  background: #444;
  padding: 15px 0;
}

#header-navigation ul.subnav-menu li {
  float: none;
  border: none;
  width: 200px;
}

#header-navigation ul.subnav-menu li a {
  display: block;
  text-align: right;
  padding: 0 20px;
  line-height: 30px;
  border: none;
}

#header-navigation .subnav-posts {
  //display: none;
  //position: absolute;
  //width: 520px;
  //left: 220px;
  //top: 25px;
  text-align: center;
  margin-top: 20px;
}

//#header-navigation ul li li.current .subnav-posts,
//#header-navigation ul li li:hover .subnav-posts,
//#header-navigation ul li li a:hover .subnav-posts {
//  display: block;
//}

#header-navigation ul li .subnav-posts a {
  line-height: 14px;
  font-weight: 400;
  display: inline;
  text-transform: none;
  padding: 0;
  border-top: none;
}

#header-navigation .subnav-posts article {
  width: 150px;
  //float: left;
  margin-left: 30px;
  display: inline-block;
}

#header-navigation .subnav-posts article img {
  width: 150px;
}

#header-navigation .subnav-posts article:first-child {
  margin-left: 0;
}

#header-navigation .subnav-posts article h3 {
  line-height: 14px;
  font-weight: 400;
  text-align: center;
  padding: 0 10px;
  margin: 8px 0 0 0;

  height: 40px;
  overflow: hidden;
}

#header-navigation .subnav-posts article .date {
  font-weight: 400;
  text-align: center;
  margin-top: 8px;
  color: white;
  font-size: 12px;
}

/**
 * Dropnav - regular drop down menu
 */

#header-navigation .dropnav-container {
  display: none;
  position: absolute;
  top: 50px;
  z-index: 10;
  background: #3f3f3f;
  margin: 0;
}

#header-navigation .dropnav-container ul {
  background: #3f3f3f;
  border-bottom: 1px solid #4D4D4D;
}

#header-navigation .dropnav-container ul.dropnav-menu li {
  position: relative;
  float: none;
  min-width: 200px;
  border-top: 1px solid #4D4D4D;
}

#header-navigation .dropnav-container ul.dropnav-menu li a {
  color: #fff;
  line-height: 18px;
  padding-top: 10px;
  padding-bottom: 10px;
}

#header-navigation .dropnav-container ul.dropnav-menu li a i.fa {
  float: right;
  line-height: 18px;
}

#header-navigation .dropnav-container ul.dropnav-menu ul {
  display: none;
  position: absolute;
  top: -1px;
  left: 200px;
  z-index: 100;
}

#header-navigation ul li:hover .dropnav-container,
#header-navigation .dropnav-container ul.dropnav-menu li:hover ul {
  display: block;
}


/**
 * Sub navigation - categories
 */

#header-navigation-sub nav {
  height: 50px;
  overflow: hidden;
}

#header-navigation-sub nav ul {
  list-style: none;
  padding: 0;
}

#header-navigation-sub nav ul li {
  float: left;
  margin: 0 10px 0 25px;
}

#header-navigation-sub nav ul li a {
  font: 700 14px/50px 'Roboto Condensed', sans-serif;
  text-transform: uppercase;
  color: #fff;
}

#header-navigation-sub nav ul li.current a,
#page-content #header-navigation-sub nav ul li a:hover {
  color: #3a3a3a;
}


/**
 * Media Queries
 */

@media (min-width: 992px) {

  #header-navigation ul li a {
    font-size: 13px;
    padding: 0 18px;
  }

  #header-navigation .subnav-container {
    width: 970px;
  }

  #header-navigation ul.subnav-menu {
    width: 250px;
  }

  #header-navigation ul.subnav-menu li {
    width: 250px;
  }

  #header-navigation .subnav-posts {
    //left: 280px;
    //width: 650px;
  }

  #header-navigation .subnav-posts article {
    width: 190px;
  }


  #header-navigation .subnav-posts article img {
    width: 190px;
  }

}

@media (min-width: $screen-lg-min) {

  #header-navigation ul li a {
    font-size: 14px;
    padding: 0 20px;
  }

  #header-navigation .subnav-container {
    width: 1170px;
  }

  #header-navigation .subnav-posts {
    //width: 850px;
  }

  #header-navigation .dropnav-container ul.dropnav-menu li {
    min-width: 240px;
  }

  #header-navigation .dropnav-container ul.dropnav-menu ul {
    left: 240px;
  }

}

/* ==========================================================================
   Slider
   ========================================================================== */

/**
 * Slider
 */

#page-slider {
  position: relative;
  margin-top: 1px;
}

//#page-slider .row {
//  float: left;
//}

#page-slider .row .row {
  float: none;
}

#page-slider .col-sm-8 {
  padding-right: 1px;
}

#page-slider .col-sm-8 article {
  margin: 0 0 1px 0;
}

#page-slider .col-sm-4 article {
  margin: 1px 0 0 0;
}

#page-slider .col-sm-4 article:first-child {
  margin-top: 0;
}

#page-slider .col-sm-8 article .overlay p {
  display: none;
}

#page-slider.slider-layout-3 article,
#page-slider.slider-layout-4 article,
#page-slider.slider-layout-5 article {
  margin-bottom: 1px;
}

#page-slider .slider-nav {
  display: none;
  position: absolute;
  width: 750px;
  top: 205px;
}

#page-slider .slider-nav a {
  position: absolute;
  left: 10px;
  display: inline-block;
  font-size: 20px;
  color: #fff;
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.3);
  text-align: center;
  line-height: 40px;
  -webkit-border-radius: 20px 20px 20px 20px;
  border-radius: 20px 20px 20px 20px;
}

#page-slider .slider-nav a.next {
  left: auto;
  right: 10px;
}

#page-slider.slider-layout-3 .slider-nav {
  top: 245px;
}

#page-slider.slider-layout-5 .slider-nav {
  top: 220px;
}


/**
 * Media Queries
 */

@media (min-width: 768px) {

  #page-slider .slider-nav {
    display: block;
  }

  #page-slider .col-sm-8 article,
  #page-slider .col-sm-3 article {
    height: 452px;
    margin: 0;
  }

  #page-slider .col-sm-8 article img,
  #page-slider .col-sm-3 article img {
    height: 452px;
  }

  #page-slider .col-sm-8 article .overlay p {
    display: block;
  }

  #page-slider .col-sm-4 {
    padding-left: 0px;
  }

  #page-slider .col-sm-4 article {
    height: 150px;
  }

  #page-slider .col-sm-4 article img {
    height: 150px;
  }

  #page-slider.slider-layout-2 .row {
    height: 452px;
    overflow: hidden;
  }

  #page-slider.slider-layout-2 .col-sm-3 {
    width: 250px;
    height: 452px;
    padding-right: 1px;
    padding-left: 0;
  }

  #page-slider .col-sm-3:first-child {
    margin-left: 15px;
  }

  #page-slider.slider-layout-3 .row {
    height: 352px;
    overflow: hidden;
    margin: 0;
  }

  #page-slider.slider-layout-4 .row {
    height: 388px;
    overflow: hidden;
    margin: 0;
  }

  #page-slider.slider-layout-3 .col-sm-6 {
    width: 375px;
    height: 211px;
    padding-right: 1px;
    padding-bottom: 1px;
    padding-left: 0;
  }

  #page-slider.slider-layout-3 .col-sm-4,
  #page-slider.slider-layout-4 .col-sm-4 {
    width: 250px;
    height: 140px;
    padding-right: 1px;
    padding-left: 0;
  }

  #page-slider.slider-layout-3 .col-sm-6 article {
    height: 210px;
  }

  #page-slider.slider-layout-3 .col-sm-6 article img {
    height: 210px;
  }

  #page-slider.slider-layout-3 .col-sm-4 article,
  #page-slider.slider-layout-4 .col-sm-4 article {
    height: 140px;
  }

  #page-slider.slider-layout-3 .col-sm-4 article img,
  #page-slider.slider-layout-4 .col-sm-4 article img {
    height: 140px;
  }

  #page-slider.slider-layout-3 .col-sm-4 article.linkbox h2,
  #page-slider.slider-layout-4 .col-sm-4 article.linkbox h2 {
    font-size: 20px;
    line-height: 24px;
  }

  #page-slider.slider-layout-4 .col-sm-12 {
    padding: 0;
  }

  #page-slider.slider-layout-5 .row {
    overflow: hidden;
    margin: 0;
  }

  #page-slider.slider-layout-5 .col-sm-6 {
    padding-right: 1px;
    padding-bottom: 1px;
    padding-left: 0;
  }

  #page-slider.slider-layout-6 .row {
    height: 305px;
  }

  #page-slider.slider-layout-6 .slider-nav {
    top: 140px;
  }

}

@media (min-width: 992px) {

  #page-slider .col-sm-8 {
    width: 635px;
  }

  #page-slider .col-sm-4 {
    width: 365px;
  }

  #page-slider .slider-nav {
    width: 970px;
  }

  #page-slider.slider-layout-2 .col-sm-3 {
    width: 242px;
  }

  #page-slider.slider-layout-3 .row {
    height: 408px;
  }

  #page-slider.slider-layout-4 .row {
    height: 488px;
  }

  #page-slider.slider-layout-3 .col-sm-6 {
    width: 485px;
    height: 243px;
  }

  #page-slider.slider-layout-3 .col-sm-4,
  #page-slider.slider-layout-4 .col-sm-4 {
    width: 323px;
    height: 165px;
  }

  #page-slider.slider-layout-3 .col-sm-6 article {
    height: 242px;
  }

  #page-slider.slider-layout-3 .col-sm-6 article img {
    height: 242px;
  }

  #page-slider.slider-layout-3 .col-sm-4 article,
  #page-slider.slider-layout-4 .col-sm-4 article {
    height: 165px;
  }

  #page-slider.slider-layout-3 .col-sm-4 article img,
  #page-slider.slider-layout-4 .col-sm-4 article img {
    height: 165px;
  }

  #page-slider.slider-layout-3 .col-sm-4 article.linkbox h2,
  #page-slider.slider-layout-4 .col-sm-4 article.linkbox h2 {
    font-size: 24px;
    line-height: 30px;
  }

  #page-slider.slider-layout-6 .row {
    height: 398px;
  }

  #page-slider.slider-layout-6 .slider-nav {
    top: 195px;
  }

}

@media (min-width: 1200px) {

  #page-slider .col-sm-8 {
    width: 835px;
  }

  #page-slider .slider-nav {
    width: 1170px;
  }

  #page-slider.slider-layout-2 .col-sm-3 {
    width: 292px;
  }

  #page-slider.slider-layout-3 .row {
    height: 552px;
  }

  #page-slider.slider-layout-4 .row {
    height: 602px;
  }

  #page-slider.slider-layout-3 .col-sm-6 {
    width: 585px;
    height: 351px;
  }

  #page-slider.slider-layout-3 .col-sm-4,
  #page-slider.slider-layout-4 .col-sm-4 {
    width: 390px;
    height: 200px;
  }

  #page-slider.slider-layout-3 .col-sm-6 article {
    height: 350px;
  }

  #page-slider.slider-layout-3 .col-sm-6 article img {
    height: 350px;
  }

  #page-slider.slider-layout-3 .col-sm-4 article,
  #page-slider.slider-layout-4 .col-sm-4 article {
    height: 200px;
  }

  #page-slider.slider-layout-3 .col-sm-4 article img,
  #page-slider.slider-layout-4 .col-sm-4 article img {
    height: 200px;
  }

  #page-slider.slider-layout-5 .col-sm-6 {
    height: 480px;
  }

  #page-slider.slider-layout-5 .col-sm-6 .col-sm-6 {
    height: 240px;
  }

  #page-slider.slider-layout-6 .row {
    height: 480px;
  }

  #page-slider.slider-layout-6 .slider-nav {
    top: 235px;
  }

}


/* ==========================================================================
   Content
   ========================================================================== */

/**
 * Content
 */

#page-content header {
  position: relative;
}

#page-content header h2 {
  font: 400 17px/17px 'Roboto Condensed', sans-serif;
  color: #444;
  text-transform: uppercase;
  margin: 0 0 15px 0;
  padding-bottom: 10px;
  border-bottom: 1px solid #f5f5f5;
}

#page-content header span.borderline {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 30px;
  height: 1px;
  background: #222;
}


/**
 * Articles
 */

article.linkbox h2 {
  font: 400 24px/30px 'Roboto Condensed', sans-serif;
}

article.linkbox h3 {
  font: 700 15px/18px 'Roboto Condensed', sans-serif;
}

article.thumb h3 {
  font: 700 16px/20px 'Roboto Condensed', sans-serif;
  margin: 0 0 10px 0;
}

article span.text {
  font-size: 12px;
}

article.thumb span.published {
  margin-bottom: 10px;
}

article span.published {
  display: block;
  margin-top: 10px;
  font-size: 10px;
  color: #777;
  text-transform: uppercase;
}

article span.published-time {
  display: block;
  font-size: 10px;
  font-weight: 700;
  color: #bbb;
  text-transform: uppercase;
}

article.linkbox .postmeta {
  position: absolute;
  font-size: 12px;
  color: #fff;
  bottom: 10px;
  left: 10px;
  opacity: 0.7;
  cursor: pointer;
}

article.linkbox .postmeta a {
  color: #fff;
  text-decoration: none;
}

article.linkbox .postmeta span {
  display: inline-block;
  margin: 0 6px;
}

article.linkbox .postmeta span:before {
  font-family: 'FontAwesome';
  margin-right: 8px;
}

article.linkbox.large .postmeta {
  left: 15px;
  bottom: 15px;
}

article.has-meta .overlay {
  padding-bottom: 35px;
}

article.large.has-meta .overlay {
  padding-bottom: 40px;
}

.fa-calendar:before {
  content: "\f133";
}

/**
 * Media Queries
 */

@media (min-width: 992px) {

  article.linkbox h2 {
    font: 400 30px/36px 'Roboto Condensed', sans-serif;
  }

}


/**
 * News Layouts
 */

.news-layout article {
  padding-bottom: 12px;
  margin-bottom: 10px;
  line-height: 18px;
  border-bottom: 1px solid #e3e3e3;
}

.news-layout article:last-child {
  border-bottom: none;
}

.news-layout article h3 {
  font-size: 13px;
  line-height: 18px;
  font-weight: 700;
  margin: 0 0 10px 0;
}

.news-layout article h3 a {
  color: #222;
}

.news-layout article h3 a:hover {
  text-decoration: underline;
}

.news-layout article img {
  float: left;
  margin: 3px 12px 3px 0;
}


/* news-lay-1 */

.news-lay-1 article h3 {
  margin: 0 0 5px 0;
}

.news-lay-1 span.published {
  margin: 0 0 5px 0;
}


/* news-lay-2 */

#page-content .news-lay-2 header h2 {
  color: #fff;
}

.news-lay-2 article {
  border: none;
  margin: 0;
}

.news-lay-2 article a h3 {
  text-align: center;
  color: #fff;
  margin-top: 5px;
}


/* news-lay-3 */

.news-lay-3 article h3 {
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  margin: 5px 0 0 0;
}


/**
 * Media Queries
 */

@media (min-width: 992px) {

  news-layout article h3 {
    font-size: 15px;
  }

  .article span.text {
    font-size: 13px;
  }

  .span.published {
    font-size: 11px;
  }

}

/**
 * Reviews
 */

article.review img {
  float: none;
}

.news-layout article.review h3 {
  text-align: center;
  height: 36px;
  overflow: hidden;
  margin-bottom: 5px;
}


/**
 * Videos
 */

.cat-videos {
  margin-top: 1px;
  margin-bottom: 1px;
}

.cat-videos .col-sm-8 {
  margin-bottom: 1px;
}

.cat-videos .col-sm-8 article {
  height: 301px;
  margin: 0;
}

.cat-videos .col-sm-8 article img {
  height: 301px;
}

.cat-videos .col-sm-4 article {
  height: 150px;
  margin: 1px 0 0 0;
}

.cat-videos .col-sm-4 article img {
  height: 150px;
}

.cat-videos .col-sm-4 article:first-child {
  margin-top: 0;
}

/**
 * Media Queries
 */

@media (min-width: 768px) {

  .cat-videos .col-sm-8 {
    padding-right: 1px;
    margin-bottom: 0;
  }

  .cat-videos .col-sm-4 {
    padding-left: 0px;
  }

}

@media (min-width: 992px) {

  .cat-videos .col-sm-8 {
    width: 300px;
  }

  .cat-videos .col-sm-4 {
    width: 206px;
  }

}

@media (min-width: 1200px) {

  .cat-videos .col-sm-8 {
    width: 350px;
  }

  .cat-videos .col-sm-4 {
    width: 289px;
  }

}


/**
 * Article page
 */

#article-post {
  header {
    padding: 15px 0 10px;
    margin: 0 0 20px 0;
    border-bottom: 1px solid #dadada;

    .author {
      margin-top: 5px;

      .author-label {
        font-weight: bold;
      }
    }
  }
}

.breadcrumb {
  padding: 0;
  margin: 0 0 8px 0;
  background: none;
}

#article-post header h1 {
  font: 400 30px/36px 'Roboto Condensed', sans-serif;
  color: #222;
  margin: 0;
}

#article-post h2 {
  font: 400 24px/30px 'Roboto Condensed', sans-serif;
  color: #222;
  margin: 25px 0 15px 0;
}

#article-post p {
  line-height: 24px;
  margin-bottom: 15px;
}

#article-post p.lead {
  font: 400 16px/24px 'Roboto Slab', serif;
  color: #222;
  margin-bottom: 25px;
}

/* Blockquote */

#article-post blockquote {
  position: relative;
  background: #f5f5f7;
  padding: 35px 80px 35px 70px;
  margin: 25px 0;
  border: none;
  -webkit-border-radius: 6px 6px 6px 6px;
  border-radius: 6px 6px 6px 6px;
}

#article-post blockquote footer {
  margin: 0;
  padding: 0;
  border: none;
}

#article-post blockquote p {
  font-size: 20px;
  line-height: 30px;
  font-style: italic;
  color: #222;
  quotes: "\201C" "\201D" "\2018" "\2019";
}

#article-post blockquote:before {
  position: absolute;
  top: 20px;
  left: 25px;
  content: open-quote;
  font: 700 54px/54px Georgia, serif;
  color: #999;
}

#article-post blockquote:after {
  position: absolute;
  bottom: 10px;
  right: 25px;
  content: close-quote;
  font: 700 54px/54px Georgia, serif;
  color: #999;
}


/* Info box */

.info-box {
  display: block;
  background: #f5f5f7;
  margin: 20px 0;
  padding: 25px;
}


/* Author box */

.author-box {
  position: relative;
  background: #f5f5f7;
  margin: 30px -10px;
  padding: 20px 10px 5px 10px;
  text-align: center;
}

.author-box img {
  margin: 0 auto;
  margin-bottom: 10px;
}

.author-box .name {
  font-size: 20px;
  color: #222;
  margin-bottom: 15px;
}

.author-box p a {
  display: inline-block;
  color: #828282;
  margin: 0 6px;
}

.author-box p a:hover {
  color: #222;
}


/* Highlights */

#article-post .row-highlights col-sm-9,
#article-post .row-highlights col-sm-3 {
  padding-top: 0;
}

#article-post .highlights h4 {
  font: 400 17px/17px 'Roboto Condensed', sans-serif;
  color: #444;
  text-transform: uppercase;
  margin: 0 0 15px 0;
  padding-bottom: 10px;
  border-bottom: 1px solid #e3e3e3;
}


/**
 * Reviewa
 */


#article-post .review h4 {
  margin-top: 0;
  padding-bottom: 8px;
  border-bottom: 1px solid #dadada;
}

#article-post .review ul {
  list-style: none;
  font-size: 13px;
  color: #777;
  padding: 0;
}

#article-post .review li {
  position: relative;
  margin-bottom: 3px;
  padding-left: 25px;
}

#article-post .review li i.fa {
  position: absolute;
  font-size: 14px;
  top: 2px;
  left: 2px;
  color: #aaa;
}

#article-post .score-overall {
  background: #444;
  text-align: center;
  padding: 15px 0;
  margin-bottom: 15px;
}

#article-post .score-number {
  display: block;
  font: 400 72px/72px 'Roboto Slab', serif;
  color: #fff;
}

#article-post .score-desc {
  display: inline-block;
  color: #fff;
  text-transform: uppercase;
  border-top: 1px solid #fff;
  margin-top: 10px;
  padding-top: 10px;
}

#article-post .progress {
  background-color: #f5f5f7;
  box-shadow: none;
  height: 40px;
}

#article-post .progress-bar {
  line-height: 40px;
  box-shadow: none;
  background-color: #444;
  border-radius: 4px;
}

#article-post .progress-bar span {
  display: inline-block;
  font-weight: 700;
  font-family: "Roboto Sondensed",sans-serif;
  text-transform: uppercase;
  margin-left: 8px;
  line-height: 26px;
  margin-top: 7px;
}

#article-post .progress-bar span.skill-number {
  font-size: 14px;
  color: #444;
  background-color: #FFF;
  border-radius: 2px;
  padding: 3px 10px;
  line-height: 20px;
  margin-right: 5px;
}

/* Footer */

#article-post footer {
  border-top: 1px solid #dadada;
  padding: 15px 0 0 0;
  margin: 50px 0 0 0;
}

#article-post footer h3 {
  display: inline-block;
  font: 400 20px/24px 'Roboto Condensed', sans-serif;
  color: #222;
  margin: 0 30px 0 0;
}

#article-post footer a {
  color: #828282;
  margin: 0 6px;
}

#article-post footer a:hover {
  color: #222;
}

/**
 * Comments
 */

#article-comments {
  margin-bottom: 50px;
}

#comments-list {
  list-style: none;
  padding: 0;
}

#comments-list li {
  list-style: none;
  margin-bottom: 10px;
}

#comments-list li ul {
  padding: 0;
  margin: 10px 0 0 0;
}

#comments-list li .comment {
  position: relative;
}

#comments-list li .comment .user-avatar {
  display: none;
}

#comments-list li .comment-text {
  position: relative;
  line-height: 24px;
  padding: 25px;
  border: 1px solid #dadada;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}

#comments-list .author-comment .comment-text {
  background: #f1d7d7;
  border-color: #eac2c2;
}

#comments-list li .comment-text p {
  margin-bottom: 0;
}

#comments-list li .comment-text header {
  font: 400 14px/24px 'Roboto Condensed', sans-serif;
  color: #999;
  margin: 0 0 10px 0;
}

#comments-list li .comment-text header h5 {
  font-size: 18px;
  line-height: 20px;
  color: #222;
  padding: 0;
  margin: 0 15px 0 0;
}

#comments-list li .comment-text header .reply {
  color: #ef3434;
  text-transform: uppercase;
}


/**
 * Iframes
 */

#page-content .head-video iframe {
  display: block;
  width: 100%;
  min-height: 280px;
  border: none;
  margin: 0;
}

#main.article iframe {
  display: block;
  width: 100%;
  border: none;
  margin: 15px 0 20px 0;
}


/**
 * Gallery and Zoomix
 */

#sidebar-gallery a,
#article-gallery a {
  display: block;
  position: relative;
}

.zoomix {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  z-index: 90;
  background: #000;
  opacity: 0;
  filter: alpha(opacity = 0);

  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -o-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);

  -webkit-backface-visibility: hidden;
}

.ie7 .zoomix{
  clear: both;
}

a:hover .zoomix {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity:0.4;
  filter: alpha(opacity = 50);
}

.zoomix .fa {
  font-size: 25px;
  line-height: 25px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -12px 0 0 -12px;
}


/**
 * Pagination
 */

.pagination > li > a, .pagination > li > span {
  padding: 8px 15px;
  color: #444;
  border-color: #ddd;
}

.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
  background-color: #444;
  border-color: #444;
}



/**
 * Media Queries
 */

@media (min-width: 768px) {

  #page-content .head-video iframe {
    min-height: 400px;
  }

  .author-box {
    text-align: left;
    margin: 40px -15px 20px -15px;
    padding: 25px 30px 10px 185px;
  }

  .author-box img {
    position: absolute;
    top: 20px;
    left: 35px;
  }

  #article-post .score-overall {
    margin-bottom: 0px;
  }

  #article-post .row-highlights .col-sm-9 {
    border-right: 1px solid #e3e3e3;
  }

  #comments-list li {
    margin-bottom: 30px;
  }

  #comments-list li ul {
    margin: 30px 0 0 125px;
  }

  #comments-list li .comment {
    position: relative;
    padding-left: 125px;
  }

  #comments-list li .comment .user-avatar {
    display: block;
    position: absolute;
    top: 0;
    left: 5px;
  }

  #comments-list li .comment-text:before {
    position: absolute;
    display: block;
    content: "";
    width: 15px;
    height: 15px;
    background: #fff;
    border-left: 1px solid #dadada;
    border-top: 1px solid #dadada;
    margin-left: -34px;
    -moz-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  #comments-list .author-comment .comment-text:before {
    background: #f1d7d7;
    border-color: #eac2c2;
  }

}

@media (min-width: 992px) {

  .author-box {
    margin: 35px -20px 35px -20px;
  }

  #article-post .row-highlights .col-sm-9 {
    padding-right: 15px;
  }

}

@media (min-width: 1200px) {

  #page-content .head-video iframe {
    min-height: 500px;
  }

  .author-box {
    margin-left: -25px;
    margin-right: -25px;
  }

  #article-post .row-highlights .col-sm-9 {
    padding-right: 25px;
  }

  #article-post .row-highlights .col-sm-3 {
    padding-left: 20px;
  }

}


/**
 * Archive page
 */

#archive-page.module-timeline article {
  padding-left: 290px;
  min-height: 230px;
}

#archive-page.module-timeline .input-append {
  position: relative;
  line-height: 30px;
  border-left: 1px solid #e3e3e3;
  height: 90px;
  margin: 30px 0 0 290px;
  padding-left: 25px;
}

#archive-page.module-timeline .input-append:before {
  position: absolute;
  content: "";
  top: 3px;
  left: -15px;
  width: 30px;
  height: 30px;
  background: #444;
  border: 3px solid #fff;
  -webkit-border-radius: 15px 15px 15px 15px;
  border-radius: 15px 15px 15px 15px;
}

#archive-page.module-timeline .input-append input {
  width: 160px;
  font-size: 24px;
  padding: 0 5px;
  border: 1px solid #e3e3e3;
}

#archive-page.module-timeline .input-append .glyphicon {
  font-size: 24px;
  margin-left: 5px;
}

#archive-page.module-timeline article .cnt {
  border-color: #e3e3e3;
  min-height: 230px;
  padding-left: 25px;
}

#archive-page.module-timeline article:last-child .cnt {
  min-height: 190px;
}

#archive-page.module-timeline article h3 {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin: 5px 0 10px 0;
}

#archive-page.module-timeline article h3 a {
  color: #222;
}

#archive-page.module-timeline article h3 a:hover {
  text-decoration: underline;
}

#archive-page.module-timeline article span.text {
  font-size: 14px;
  line-height: 20px;
  color: #5c5c5c;
}

#archive-page.module-timeline article img {
  position: absolute;
  top: 30px;
  left: 0;
  width: 265px;
  height: 160px;
}

#archive-page.module-timeline article span.published {
  width: 265px;
  top: 2px;
  font-size: 12px;
  color: #999;
}

#archive-page.module-timeline article span.category {
  font-size: 12px;
  line-height: 14px;
}

#archive-page.module-timeline article i.bullet {
  left: -10px;
  width: 20px;
  height: 20px;
  background: #444;
  border-color: #fff;
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
}

/**
 * Author page
 */

#main.author {
  padding-top: 0;
}

#author-page .author-box {
  margin-top: 0;
}


/* ==========================================================================
   Sidebar
   ========================================================================== */

#sidebar .row {
  margin: 0;
}


#sidebar header h2 {
  font-size: 17px;
  font-weight: 400;
  color: #fff;
  border-color: #4e4e4e;
}

#sidebar header span.borderline {
  background: #fff;
}


/**
 * Timeline module
 */

.module-timeline {
  margin-bottom: 30px;
}

.module-timeline article {
  position: relative;
  padding-left: 80px;
  margin: 0;
}

.module-timeline article .cnt {
  position: relative;
  border-left: 1px solid #575757;
  padding: 20px 0 20px 20px;
}

.module-timeline article:last-child .cnt {
  padding-bottom: 0;
}

.module-timeline article span.published {
  position: absolute;
  width: 60px;
  text-align: right;
  left: 0;
  top: 2px;
  margin-top: 0;
  color: #999;
}

.module-timeline article span.published-time {
  position: absolute;
  width: 60px;
  text-align: right;
  left: 0;
  top: 16px;
}

.module-timeline article span.category {
  position: absolute;
  top: 2px;;
  font-size: 11px;
  line-height: 11px;
  font-weight: 700;
  text-transform: uppercase;
}

.module-timeline article h3 {
  font: 700 15px/18px 'Roboto Condensed', sans-serif;
  margin: 0;
}

.module-timeline article h3 a {
  color: #ccc;
}

.module-timeline article h3 a:hover {
  color: #fff;
}

.module-timeline article i.bullet {
  position: absolute;
  top: 0;
  left: -8px;
  width: 15px;
  height: 15px;
  background: #fff;
  border: 3px solid #444;
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
}


/**
 * Quote module
 */

#sidebar .module-quote {
  background: #8e6161;
  padding: 15px;
  margin: 0 -15px 1px -15px;
}

#sidebar .module-quote header h2 {
  border-color: #8e6161;
}

#sidebar blockquote {
  border: none;
  padding: 0 0 0 45px;
  margin: 0;
}

#sidebar blockquote p {
  font: italic 400 18px/24px 'Roboto Slab', serif;
  color: #fff;
  margin: 0 0 15px 0;
}

#sidebar blockquote footer {
  font-size: 13px;
  color: #ccaaaa;
}


/**
 * Singles module
 */

#sidebar .module-singles {
  background: #54878a;
  padding: 15px;
  margin: 0 -15px 1px -15px;
}

#sidebar .module-singles header h2 {
  border-color: #5d8d90;
}

#sidebar .module-singles ul {
  list-style: none;
  padding: 0;
}

#sidebar .module-singles ul li {
  border-top: 1px solid #769fa1;
  padding-top: 15px;
  margin-top: 15px;
}

#sidebar .module-singles ul li:first-child {
  border-top: none;
  margin-top: 0;
}

#sidebar .module-singles li .glyphicon {
  float: left;
  color: #9dc5c7;
  font-size: 28px;
  margin-right: 12px;
}

#sidebar .module-singles li a {
  display: block;
  font: 400 15px/18px 'Roboto Slab', serif;
  color: #fff;
}

#sidebar .module-singles li span.author {
  color: #9dc5c7;
}


/**
 * News module
 */

#sidebar .module-news {
  margin-bottom: 5px;
}

#sidebar .module-news article {
  padding: 15px 0;
  margin: 0;
  border-top: 1px solid #575757;
  min-height: 80px;
}

#sidebar .module-news article:first-child {
  padding-top: 0;
  border: none;
}

#sidebar .module-news article h3 {
  font: 700 15px/18px 'Roboto Condensed', sans-serif;
  margin: 0;
  max-height: 54px;
  overflow: hidden;
}

#sidebar .module-news article h3 a {
  color: #ccc;
}

#sidebar .module-news article h3 a:hover {
  color: #fff;
}

#sidebar .module-news article span.published {
  display: block;
  font-size: 11px;
  line-height: 11px;
  color: #999;
  margin: 3px 0 6px 0;
}

#sidebar .module-news article span.published span.category {
  display: inline-block;
  padding-left: 10px;
  margin-left: 10px;
  border-left: 1px solid #999;
}

#sidebar .module-news article img {
  display: block;
  float: left;
  margin-right: 12px;
}


/**
 * Photos module
 */

#sidebar .module-photos p {
  font-size: 13px;
}

#sidebar .module-photos .row img {
  width: 100%;
}

#sidebar .module-photos .col-xs-4 {
  padding: 0;
  border-left: 1px solid #444;
}

#sidebar .module-photos article {
  margin: 0 0 1px 0;
}

#sidebar .module-photos .col-xs-4:first-child {
  border-left: none;
}

#sidebar .module-photos article a {
  display: block;
  position: relative;
}


/**
 * Media Queries
 */

@media (min-width: 992px) {

  #sidebar .module-photos .col-xs-4:first-child img {
    width: 100px;
    height: 79px;
  }

}

@media (min-width: 1200px) {

  #sidebar .module-quote {
    padding: 20px 25px;
    margin: 0 -25px 1px -25px;
  }

  #sidebar .module-singles {
    padding: 20px 25px;
    margin: 0 -25px 1px -25px;
  }

}


/* ==========================================================================
   Footer
   ========================================================================== */

/**
 * Navigation
 */

#page-footer {
  position: relative;
}

#foot-menu {
  height: 70px;
  border-bottom: 1px solid #e4e4e4;
  overflow: hidden;
}

#foot-menu ul {
  padding: 0;
}

#foot-menu ul li {
  list-style: none;
  float: left;
  margin-right: 1px;
}

#foot-menu ul li a {
  display: block;
  font-size: 14px;
  font-weight: 700;
  line-height: 68px;
  color: #222;
  text-transform: uppercase;
  padding: 0 22px;
  border-bottom: 1px solid #222;
}

#foot-menu ul li.current a,
#foot-menu ul li a:hover {
  color: #999;
}

#foot-menu ul li.options {
  float: right;
  padding-right: 10px;
}

#foot-menu ul li.options a {
  display: inline-block;
  font-size: 12px;
  color: #999;
  padding: 0 10px;
  border: none;
}

#foot-menu ul li.options a span.glyphicon {
  padding-left: 10px;
  color: #999;
}

#foot-menu ul li.options a:hover,
#foot-menu ul li.options a:hover span {
  color: #222;
}


/**
 * About
 */

#page-footer .about {
  font-size: 13px;
  color: #888;
  padding: 0 0 25px 0;
}

#page-footer .row {
  margin: 0;
}

#page-footer .about div {
  font-size: 13px;
  color: #888;
  margin-top: 30px;
}

#page-footer .about h3 {
  text-align: center;
}

#page-footer .about h4 {
  font-size: 15px;
  color: #444;
}

#page-footer .about a {
  color: #828282;
}

#page-footer .about a i.fa {
  margin-right: 10px;
}

#page-footer .about a:hover {
  color: #222;
}

//#page-footer .about ul {
//  list-style: none;
//  padding: 0;
//}
//
//#page-footer .about ul li {
//  float: left;
//  width: 50%;
//}

/**
 * Copyright
 */

#page-footer .copyright {
  font-size: 13px;
  color: #888;
  padding: 10px 0;
  border-top: 1px solid #e4e4e4;
}

#page-footer .copyright div {
  text-align: center;
}

#page-footer .copyright a {
  font-weight: 700;
  color: #444;
}


/**
 * Media Queries
 */

@media (min-width: 768px) {

  #page-footer .about div {
    margin-top: 15px;
  }

  #page-footer .about div:first-child {
    margin-top: 0;
  }

  #page-footer .copyright div {
    text-align: left;
  }

  #page-footer .copyright div.text-right {
    text-align: right;
  }

}

@media (min-width: 992px) {

  #page-footer .about h3 {
    text-align: left;
    padding-top: 15px;
  }

  #page-footer .about div {
    padding: 0 20px 0 20px;
  }

  #page-footer .about div:first-child {
    padding-left: 5px;
  }

}


/* ==========================================================================
   Banners
   ========================================================================== */

/**
 * Banners
 */

.ad {
  text-align: center;
  margin: 0 0 20px 0;
  overflow: hidden;
}

.ad-separator {
  background: #383838;
  padding: 20px;
  margin: 0 -20px 20px -20px;
}

/**
 * Media Queries
 */

@media (min-width: 992px) {

  .ad-separator {
    padding: 15px;
    margin: 0 -15px 20px -15px;
  }
}

@media (min-width: 1200px) {

  .ad-separator {
    padding: 20px 25px;
    margin: 0 -25px 20px -25px;
  }
}
