@import 'admin/bootstrap-custom';
//@import '../lib/bootstrap-sass/assets/stylesheets/bootstrap/theme';
// FontAwesome
$fa-font-path: "../../lib/components-font-awesome/fonts";
@import '../lib/components-font-awesome/scss/font-awesome';
// Slick
@import '../lib/slick-carousel/slick/slick.scss';
$slick-font-path: '../../lib/slick-carousel/slick/fonts/';
@import '../lib/slick-carousel/slick/slick-theme.scss';

.slick-slider {
  margin: 0;
}

// Theme stuff
//$wi-path: "/lib/weather-icons/font";
//@import '../lib/weather-icons/sass/weather-icons';
//@import '../lib/sidr/dist/stylesheets/jquery.sidr.dark';
@import '../css/jquery.sidr.dark';

@import 'site/utility';
// Theme specific
@import 'site/theme';
@import '../css/colors';

.bordered-img {
  border: 1px solid #eaeaea;
}

.gym-recommendations {
  article {
    .image {
      @extend .bordered-img;

      float: left;
      margin-right: 12px;
      width: 100px;
      height: 80px;
      background-position: center;
      background-size: cover;
    }

    .gym-description {
      margin-left: 112px;
      color: #bbb;

      .tags {
        font-size: 11px;
        line-height: 1;
        color: #999;
        margin: 3px 0 6px 0;
      }

      .location {
        font-size: 12px;
      }
    }
  }
}

//.nav > li > a {
//  &:hover, &:focus {
//    background-color: transparent;
//  }
//}

// Search

#header-navigation {
  z-index: 100;

  li.home {
    img {
      display: none;
      width: 135px;
    }
  }

  ul li.menu-search {
    float: right;
    border-right: none;

    form {
      height: 36px;
      border: 2px solid #4a4a4a;
      text-align: left;
      margin-top: 8px;
      padding: 0 10px;
      border-radius: 25px;
      //box-shadow: inset 0 3px 0 0 #3d3d3d;
      overflow: hidden;

      input {
        line-height: 30px;
        color: #999;
        border: none;
        padding: 0 5px;
        background: transparent;
        width: 120px;
      }

      button {
        line-height: 30px;
        height: 30px;
        color: #a1a1a1;
        border: none;
        width: 30px;
        background: transparent;
      }

      button[type=button] {
        display: none;
      }
    }

    @media (max-width: $screen-md-max) {
      form {
        padding-left: 1px;
        padding-right: 1px;

        input {
          display: none;
        }

        button[type=submit] {
          display: none;
        }

        button[type=button] {
          display: inline-block;
        }
      }
    }
  }

  .subnav-posts article img {
    @extend .bordered-img;
  }
}

nav-menu {
  display: block;

  .search-bar {
    height: 0;
    transition: height .3s;
    background: #E1785C;
    overflow: hidden;

    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;

    &.open {
      height: 47px;
    }

    input {
      border: none;
      background: transparent;
      color: white;
      font-size: 18px;
      line-height: 32px;
      margin: 6px 8px;
      border-bottom: 1px solid rgba(255,255,255,.8);
      display: block;
      width: 100%;

      &:focus {
        outline: 0; // for chrome
      }

      @include placeholder {
        color: rgba(255,255,255,.9);
      }
    }

    button {
      visibility: hidden;
      position: absolute;
      z-index: -10;
    }

    @media (min-width: $screen-lg-min) {
      display: none;
    }
  }
}

#header-navigation.is_stuck {
  .nav > li {
    & > a {
      padding-left: 10px;
      padding-right: 10px;
    }

    &.home {
      img {
        display: inline-block;

        @media (max-width: $screen-sm-max) {
          width: 85px;
        }
      }

      .fa {
        display: none;
      }

      & > a {
        padding: 0;
      }
    }
  }

  ul li.menu-search {
    input {
      width: 130px;
    }

    @media (max-width: $screen-md-max) {
      form {
        padding-left: 5px;
        padding-right: 5px;

        input {
          width: 62px;
        }

        button {
          padding-right: 0;
        }
      }
    }
  }
}

#header-branding .header-row {
  padding-top: 9px;

  h1, .ad-container, .social-buttons {
    display: inline-block;
    vertical-align: middle;
  }

  h1 {
    padding-right: 19px;

    img {
      width: 210px;
      height: 79px;
    }
  }

  .ad-container {
    padding: 0 19px;
    position: relative;

    ad-space {
      display: inline-block;
    }

    &:before, &:after {
      content: '';
      display: block;
      background-color: #5b5b5b;
      width: 1px;
      height: 48px;
      position: absolute;
      top: 50%;
      margin-top: -24px;
    }

    &:before {
      left: 0;
    }

    &:after {
      right: 0;
    }
  }

  .social-buttons {
    padding-left: 19px;

    a {
      display: inline-block;

      .fa {
        color: white;
        font-size: 42px;
      }
    }
  }

  @media (max-width: $screen-sm-max) {
    h1 {
      padding-left: 0;
      padding-right: 0;

      img {
        width: 140px;
        height: 53px;
      }
    }

    .ad-container {
      padding-left: 0;
      padding-right: 0;

      &:before, &:after {
        display: none;
      }
    }

    .social-buttons {
      display: none;
    }
  }
}

body {
  background-image: url('../../images/ubg2.jpg');

  #page-content {
    background-color: white;

    #main {
      border: 1px solid #eaeaea;
    }
  }

  news-list .zoom-thumb-container {
    @extend .bordered-img;
  }

  footer {
    margin-top: 20px;
    background-color: white;
  }
}

ad-space {
  @extend .bordered-img;
  display: inline-block;
  overflow: hidden;

  & > iframe {
    display: block;
  }
}

.zoom-thumb-container {
  display: block;
  overflow: hidden;

  .zoom-thumb {
    transition: transform 0.5s cubic-bezier(0.49, -0.01, 0.42, 0.81), opacity .3s;
  }

  &:hover {
    .zoom-thumb {
      transform: scale(1.2);
    }
  }
}

#sidr {
  display: block;

  form {
    button {
      visibility: hidden;
      position: absolute;
      z-index: -10;
    }
  }
}

.linkbox.small {
  @include aspect-ratio(265, 160);
}

.linkbox.vertical-slider-item {
  @include aspect-ratio(350, 150);
  //width: 100%;

  .img {
    width: 100%;
    height: 100%;
  }
}

.linkbox.large {
  .img {
    width: 819px;
    height: 452px;
  }
}

.error-page {
  background: white;
  box-shadow: 0 0 12px 0 #999;
  margin-top: 30px;
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;

  h1 {
    margin: 0;
  }

  .error-code {
    background-color: #333;
    display: inline-block;
    border-radius: 50%;
    color: white;
    height: 60px;
    width: 60px;
    line-height: 60px;
    font-size: 24px;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .error-main-description {
    font-size: 18px;
  }

  .error-description {
    margin-top: 20px;
  }
}

[lazy-src] {
  opacity: 0;
  transition: .3s opacity;

  &.lazy-src-loaded {
    opacity: 1;
  }
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

.login-box {
  margin-top: 30px;
  margin-bottom: 30px;
  box-shadow: 0 0 12px 0 #ccc;
  background-color: white;
}

div.img {
  background-size: cover;
  background-position: center;
}